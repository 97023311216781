import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import shape1 from "@images/shapes/cta-1-shape-1.png";
import { HOME_CONSEIL_DATA } from "@data";
const HomeConseil = () => {
  const { sectionTitle, paragraph,options,button } = HOME_CONSEIL_DATA;
  return (
    <section id="features" className="service-one">
      
      <Container>
        <div className="block-title text-center">
          <p>{sectionTitle.text}</p>
          <h3>{sectionTitle.title}</h3>
        </div>
        <div className="cta-two__text new-line">
          <p>{paragraph}</p>
        </div>
        <ul className="list-unstyled cta-two__list">
          {options.map(({ label }, index) => (
            <li key={`options-key-${index}`}>
              <i className="fa fa-check-circle"></i>
              {label}
            </li>
          ))}
        </ul>
        <div style={{ textAlign: `center` }}>
          <Link className="thm-btn cta-one__btn" to={button.link}>
                    <span>{button.label}</span>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default HomeConseil;
