import React from "react";
import {Link} from "gatsby";

const HomeServiceCard = ({ extraClassName, icon, title, text, button}) => {
  return (
    <div className="service-one__single">
      <div className={`service-icon icon-${extraClassName}`}>
        <div className={`icon-box icon-box-${extraClassName}`}>
          <i className={icon}></i>
        </div>
      </div>
      <div className="text new-line">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>

      <p></p>

      <Link to={button.link} className="thm-btn pricing-one__btn">
          <span>{button.label}</span>
      </Link>
    </div>
  );
};

export default HomeServiceCard;
