import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";

import HomeIntro from "@components/home-intro";
import HomeService from "@components/home-service/home-service";
import HomeConseil from "@components/home-conseil";
import HomeSolutions from "@components/home-solutions";
import HomeFormation from "@components/home-formation";

import HomeQawhat from "@components/home-qawhat";
import HomeLuzean from "@components/home-luzean";
import BlogHome from "@components/blog/blog-home";

import Video from "@components/video";
import AppFeature from "@components/app-feature";
import CallToAction from "@components/call-to-action";
import PriceHome from "@components/price/price-home";
import TestimonialsHome from "@components/testimonials/testimonials-home";
import AppShot from "@components/app-shot";

// import FAQ from "@components/faq";
// import Brands from "@components/brands";
// import Subscribe from "@components/subscribe";
import Contact from "@components/contact";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";

import { graphql } from "gatsby";

const HomeOne = ({ data }) => {
  const { site, lastPosts } = data;
  return (
    <Layout pageTitle="Home || Prompt Breeders">
      <Header />
      <HomeIntro />
      <HomeService />
      <BlogHome data={{ site: site, lastPosts: lastPosts }} />
      <HomeConseil />
      <HomeSolutions />
      <HomeFormation />
      

      <Contact />

      <Footer />
      <MobileNav />
    </Layout>
  );
};

export const data = graphql`
  query lastBlogQuery($limit: Int = 3) {
    site {
      siteMetadata {
        blogSection {
          sectionTitle {
            text
            title
          }
        }
      }
    }
    lastPosts: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
    ) {
      nodes {
        frontmatter {
          image
          title
        }
        fields {
          date(formatString: "DD/MM/YYYY")
        }
        excerpt(pruneLength: 100)
      }
    }
  }
`;
// export const blogsData = graphql`
//   query blogsQuery($limit: Int = 3) {
//     allMarkdownRemark(
//       sort: { fields: frontmatter___date, order: DESC }
//       limit: $limit
//     ) {
//       nodes {
//         frontmatter {
//           date
//           image
//           text
//           title
//           label
//         }
//       }
//     }
//   }
// `;

// const HomeOne = () => {
//   return (
//     <Layout pageTitle="Home Page || Prompt Breeders">
//       <Header />
//       <HomeIntro />
//       <HomeService />
//       <CallToAction />
//       <Video />
//       <AppFeature />
//       <BlogHome />
//       <Brands />
//       <Subscribe />
//       <Footer />
//       <MobileNav />
//     </Layout>
//   );
// };

export default HomeOne;
