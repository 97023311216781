import React from "react";
import { Link } from "gatsby";
var slugify = require("slugify");

const BlogCard = ({ date, image, title, excerpt, content }) => {
  const link = `/${slugify(title)}`;
  return (
    <div className={`blog-one__single ${link}`}>
      <div className="blog-one__image">
        <img src={image} alt="" />
        <div className="blog-hover-box">
          <div className="box">
            <div className="icon-box">
              <Link to={link}>
                <i className="far fa-link"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-one__content">
        <div className="blog-one__meta">
          <a href="#">
            <i className="far fa-calendar-alt"></i> {date}
          </a>
          {/* <a href="#">
            <i className="far fa-comments"></i> {commentCount} comments
          </a> */}
        </div>
        <h3>
          <Link to={link}>{title}</Link>
        </h3>
        <div className="blog-one__text">
          <p>{excerpt}</p>
        </div>
        {/* <Link to={link} className="thm-btn blog-one__btn">
          <span>label</span>
        </Link> */}
      </div>
    </div>
  );
};

export default BlogCard;
