import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import shape from "@images/shapes/cta-2-shape-1.png";
import { HOME_LUZEAN_DATA } from "@data";
const HomeLuzean = () => {
  const { sectionTitle, image, paragraph, options, button } = HOME_LUZEAN_DATA;
  return (
    <section className="cta-two"
    style={{ background: `#FFFFFF` }}>
      <img src={shape} className="cta-two__bg-shape-1" alt="" />
      <Container>
        <Row>
          <Col xl={6} className="col-xl-6">
            <div className="cta-two__content">
              <div className="block-title cus-pb">
                <p>{sectionTitle.text}</p>
                <h3>{sectionTitle.title}</h3>
              </div>
              <div className="d-xl-none">
                <div
                  className="cta-two__moc wow fadeInLeft"
                  data-wow-duration="1500ms"
                >
                  <img src={image} className="cta-one__moc-img" alt="" />
                </div>
                <p></p>

            </div>


              <div className="cta-two__text new-line">
                <p>{paragraph}</p>
              </div>
              <ul className="list-unstyled cta-two__list">
                {options.map(({ label }, index) => (
                  <li key={`options-key-${index}`}>
                    <i className="fa fa-check-circle"></i>
                    {label}
                  </li>
                ))}
              </ul>
              <div style={{ textAlign: `center` }}>
                <Link className="thm-btn pricing-one__btn" to={button.link}>
                    <span>{button.label}</span>
                </Link>
              </div>
            </div>
          </Col>
          <Col xl={6} className="col-xl-6 d-none d-xl-block">
            <div
              className="cta-two__moc wow fadeInLeft"
              data-wow-duration="1500ms"
            >
              <img src={image} className="cta-one__moc-img" alt="" />
            </div>
            <p></p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeLuzean;
