import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "gatsby";
import BlogCard from "@components/blog/blog-card";
import blogShape1 from "@images/blog/blog-1-shape-1.png";
import blogShape2 from "@images/blog/blog-1-shape-2.png";
import blogShape3 from "@images/blog/blog-1-shape-3.png";
// import { BLOG_DATA } from "@data";

const BlogHome = ({ data }) => {
  //const { sectionTitle, posts } = BLOG_DATA;
  const { title, text } = data.site.siteMetadata.blogSection.sectionTitle;
  const blogPosts = data.lastPosts.nodes;

  return (
    <section className="blog-one" id="blog" style={{ background: `#FFFFFF` }}>
      <div
        className="blog-1-shape-1"
        style={{ backgroundImage: `url(${blogShape1})` }}
      ></div>
      <div
        className="blog-1-shape-2"
        style={{ backgroundImage: `url(${blogShape2})` }}
      ></div>
      <div
        className="blog-1-shape-3"
        style={{ backgroundImage: `url(${blogShape3})` }}
      ></div>
      <Container>
        <div className="block-title text-center">
          <p>{text}</p>
          <h3>{title}</h3>
        </div>
        <Row>
          {blogPosts.map((blogPost, index) => (
            <Col key={`blog-card-key-${index}`} lg={4}>
              <BlogCard
                image={blogPost.frontmatter.image}
                extraClassName=" "
                date={blogPost.fields.date}
                title={blogPost.frontmatter.title}
                excerpt={blogPost.excerpt}
                content={blogPost.rawMarkdownBody}
                // text={blogPost.frontmatter.text}
                // label={blogPost.frontmatter.label}
                // link={blogPost.frontmatter.link}
              />
            </Col>
          ))}
        </Row>
        <div style={{ textAlign: `center` }}>
          <Link className="thm-btn pricing-one__btn" to="/blog">
            <span>All blog posts</span>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default BlogHome;
