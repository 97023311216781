import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import ServiceCard from "@components/home-service/home-service-card";
import { HOME_SERVICE_DATA } from "@data";
const HomeService = () => {
  const { sectionTitle, posts } = HOME_SERVICE_DATA;
  return (
    <section id="home-service" className="service-one">
      <Container>
        <div className="block-title text-center">
          <p>{sectionTitle.text}</p>
          <h3>{sectionTitle.title}</h3>
        </div>
        <Row>
          {posts.map(({ extraClassName, icon, title, text, button}, index) => (
            <Col xl={4} lg={4} md={6} key={`service-card-key-${index}`}>
              <ServiceCard
                extraClassName={extraClassName}
                icon={icon}
                title={title}
                text={text}
                button={button}
              />
            </Col>
          ))}
        </Row>
      </Container>
      <div style={{ textAlign: `center` }}>
          <p/>
          <Link className="thm-btn pricing-one__btn" to={"#contactform"}>
              <span>Contact Us</span>
          </Link>
          <p/>
      </div>

    </section>
  );
};

export default HomeService;
