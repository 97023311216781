import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

const HomeIntro = () => {
  return (
    <section className="banner-one" id="home">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
              <h3>
              Unleashing Generative AI for your Business
               
              </h3>
              <p>
              Generative AI is now in the hands of everyone, eliciting both excitement and concerns. 
              Its level of maturity and widespread adoption are already reshuffling the deck in numerous sectors. 
              </p>
              <p>
              The Prompt Breeders team, specialized in generative AI, advises and assists you in understanding, managing risks, utilizing, building and anticipating. 
              </p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default HomeIntro;
